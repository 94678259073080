<form action="" class="grip col-12 max-w-full pb-5" [formGroup]="form" (submit)="submit()">
  <div class="grid formgrid p-fluid">
    <div class="field mb-4 col-12">
      <label class="font-medium text-900">{{ 'Language' | translate }}</label>
      <offconon-langauge-dropdown-virtual-scroll-common
        [selectedId]="actual_language_id"
        [parameters]="language_parameters"
        [languageFormControl]="languageFormControl" />
      <small class="block mt-1 line-height-3 text-600 text-xs font-italic">{{
        'Our system supports multilingual display. Select the language you want to add or edit.'
          | translate
      }}</small>
    </div>

    <div class="field mb-4 col-12">
      <span class="p-float-label">
        <input formControlName="name" id="name" type="text" pInputText class="w-full" />
        <label for="name"> {{ 'Name' | translate }}</label>
      </span>
      <small class="block mt-1 line-height-3 text-600 text-xs font-italic"
        >{{ 'Summarize your service in a few words.' | translate }}
        {{ 'Maximum characters:' | translate }} 85
      </small>
    </div>

    <div class="field mb-4 col-12">
      <label for="description" class="font-medium text-900">
        {{ 'Description' | translate }} ( {{ 'Optional' | translate }} )</label
      >
      <textarea
        id="description"
        type="text"
        pInputTextarea
        rows="3"
        name="description"
        [autoResize]="false"
        [formControlName]="'description'"></textarea>
      <small class="block mt-1 line-height-3 text-600 text-xs font-italic"
        >{{ 'Describe in a few words what this category is.' | translate }}
        {{ 'Maximum characters:' | translate }} 500
      </small>
    </div>

    <div class="field mb-4 col-12">
      <label class="text-900"> {{ 'Order among the categories' | translate }}</label>
      <p-inputNumber
        formControlName="order_id"
        buttonLayout="horizontal"
        inputId="horizontal"
        spinnerMode="horizontal"
        decrementButtonClass="p-button-text p-button-danger py-1 px-1"
        incrementButtonClass="p-button-text p-button-success py-1 px-1"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        [inputStyleClass]="'w-full text-center py-3 px-1 border-transparent text-primary font-bold'"
        [style]="{ width: '100%' }"
        [styleClass]="'border-1 surface-border border-round '"
        [showButtons]="true"
        [step]="1"
        [min]="1"
        [max]="9000000000000" />
      <small class="block mt-1 line-height-3 text-600 text-xs font-italic">{{
        '(Optional) If you want, you can organize the rules in the order in which they appear.'
          | translate
      }}</small>
    </div>
  </div>
  <button
    class="p-button-rounded w-full mt-4"
    pButton
    type="submit"
    [loading]="loading"
    [disabled]="form.invalid"
    label="{{ 'Save' | translate }}"></button>
</form>
