<offconon-shared-ui-virtualscroll-dropdown
  [showClear]="false"
  [optionTemplate]="optionTemplate"
  [selectedOptionTemplate]="selectedOptionTemplate"
  [selectOptions]="(locationList$ | async) || []"
  [selectedOption]="$any(commonDropdownAndMultiselectService.selectedOptions$) | async"
  (selectedOptionOutput)="select($event)"
  (paginationAndSearchOutput)="
    commonDropdownAndMultiselectService.onDataChange($event, dropdownData$, {
      keyword_translation: $event.searchString,
      category_type
    })
  " />

<ng-template #optionTemplate let-item="item">
  <div class="flex align-items-center">
    @if (!item.cover_path && item.name) {
      <offconon-monogram [avatarClass]="'h-3rem w-3rem ml-auto mt-2'" [name]="item.name" />
    }

    @if (item.cover_path) {
      <p-avatar styleClass="mt-2" size="large" shape="circle" image="{{ item.cover_path }}" />
    }

    <div class="ml-1">
      <span class="block mb-1 mt-1 text-sm font-medium white-space-nowrap">
        {{ item.name }}
      </span>

      <div
        class="m-0 text-xs text-blue-500"
        [innerHTML]="item.category_path | joinCategoryPath: 'pi pi-chevron-right' : ' '"></div>
    </div>
  </div>
</ng-template>
<ng-template #selectedOptionTemplate let-item="item">
  @if (item) {
    <div class="flex align-items-center">
      @if (!item.cover_path && item.name) {
        <offconon-monogram [avatarClass]="'h-3rem w-3rem ml-auto mt-2'" [name]="item.name" />
      }

      @if (item.cover_path) {
        <p-avatar styleClass="mt-2" size="large" shape="circle" image="{{ item.cover_path }}" />
      }

      <div class="ml-1">
        <span class="block mb-1 mt-1 text-sm font-medium white-space-nowrap">
          {{ item.name }}
        </span>

        <div
          class="m-0 text-xs text-blue-500"
          [innerHTML]="item.category_path | joinCategoryPath: 'pi pi-chevron-right' : ' '"></div>
      </div>
    </div>
  }
</ng-template>

@if (categoryFormControl.value && create_new) {
  <small class="block mt-1 line-height-3 cursor-pointer" (click)="createNew()">
    {{ block_description }}
    {{ 'You can create a new one here.' | translate }}
    <span class="text-blue-500">{{ 'Click here' | translate }}. </span>

    <span class="text-red-500">
      {{ 'Select the main category under which you want to create the new category.' | translate }}
    </span>
  </small>
}

<p-sidebar
  #creatorSideBarRef
  [fullScreen]="true"
  [appendTo]="'body'"
  [modal]="false"
  [closeOnEscape]="true"
  [showCloseIcon]="false"
  [(visible)]="creatorSideBar">
  <div class="flex flex-column lg:flex-row align-items-center justify-content-between mb-3">
    <div class="text-center lg:text-left lg:pr-8">
      <div class="text-primary font-light mt-3 mb-1 text-xl">
        {{ 'Create new category' | translate }}
      </div>
      <div class="text-700 text-sm font-italic mb-3 mt-2">
        {{
          'Select the main category under which you want to create the new category.' | translate
        }}
      </div>
    </div>
    <div class="mt-2 lg:mt-0 flex flex-wrap lg:flex-nowrap">
      <button
        pButton
        icon="pi pi-times"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded p-button-danger font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Close' | translate }}"
        (click)="getClosePanel()"></button>
    </div>
  </div>
  <p-divider class="w-full" />

  <div class="p-2">
    <div class="pt-3">
      @if (creatorSideBar && categoryFormControl.value && create_new) {
        <offconon-category-template-create-common-form
          [parameters]="parameters"
          [parent_id]="categoryFormControl.value"
          (formSavedEvent)="savedForm($event)" />
      }
    </div>
  </div>
</p-sidebar>
